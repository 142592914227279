<template>
  <!-- 资料库>新增资料 -->
  <div class="dict">
    <el-page-header
      style="margin-bottom: 1.04167vw"
      @back="goBack"
      content="目标列表/目标进度详情"
    >
      <template slot="title">
        <i class="el-icon-arrow-left"></i>返回
      </template>
    </el-page-header>
    <div v-loading="loading">
      <el-row
        :gutter="20"
        style="margin-bottom: 1.5625vw"
        v-if="targetInfo.id"
      >
        <el-col :span="6">
          <div class="grid-content bg-purple">
            目标状态：{{ targetInfo.status.label }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            创建时间：{{ targetInfo.createTime }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            截止时间：{{ targetInfo.endTime }}
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            更新时间：{{ targetInfo.updateTime }}
          </div>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        v-if="targetInfo.id"
      >
        <el-col :span="2">
          <div class="grid-content bg-purple">
            <!-- targetInfo.donePer -->

            <el-progress
              :width="80"
              type="circle"
              :format="val=>{return val?val+'%':'0.00%'}"
              :percentage="parseFloat(targetInfo.donePer)"
            ></el-progress>
          </div>
        </el-col>
        <el-col :span="6">
          <div
            style="line-height: 4.16667vw; font-size: 1.14583vw"
            class="grid-content bg-purple"
          >
            {{ targetInfo.targetName }}
          </div>
        </el-col>
        <el-col :span="5">
          <div
            style="line-height: 4.16667vw; font-size: 1.14583vw"
            class="grid-content bg-purple"
          >
            当前进度：{{ targetInfo.doneValue?targetInfo.doneValue:0 }}
            {{ targetInfo.targetUnit }}
          </div>
        </el-col>
        <el-col :span="5">
          <div
            style="line-height: 4.16667vw; font-size: 1.14583vw"
            class="grid-content bg-purple"
          >
            目标： {{ targetInfo.targetValue }} {{ targetInfo.targetUnit }}
          </div>
        </el-col>
      </el-row>
      <div
        class="batchBtn"
        v-if="targetInfo.status"
      >
        <el-button
          :disabled="targetInfo.status.value != 1"
          @click="supervisehandelBatchSave(selectData)"
          size="medium"
          type="primary"
        >批量督办</el-button>
        <div>
          <el-checkbox
            v-model="isOpenAll"
            @change="changeOpenAll"
          >展开全部</el-checkbox>
        </div>
      </div>
      <!--  -->
      <el-table
        :header-cell-style="{
              background: '#F1F4F9',
              color: '#5E6D9B',
              fontWeight: '400',
              'text-align':'center'
            }"
        :data="tableData"
        v-loading="tableLoading"
        style="width: 100%; margin-bottom: 1.04167vw"
        :row-key="row => { return row.id }"
        :expand-row-keys="expandRow"
        ref="tableData"
        :tree-props="{ children: 'childList', hasChildren: 'hasChildren' }"
        show-summary
        :summary-method="getSummaries"
        @select="handleSelectRow"
        @select-all="selectAll"
        @selection-change="handleSelect"
      >
        <!-- 
        @select-all="handleSelectAllChange"
        @selection-change="handleSelectionChange" -->
        <el-table-column
          type="selection"
          :selectable="selectable"
          align="center"
          width="60"
        >

        </el-table-column>
        <el-table-column
          prop="companyName"
          label="分公司"
          align="left"
        >
        </el-table-column>
        <el-table-column
          prop="campusName"
          align="center"
          label="园校"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="dutyUser"
          align="center"
          width="200"
          label="指定人/岗位"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.dutyUserName">{{scope.row.dutyUserName}}</span>
            <span v-else>{{scope.row.jobId}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="targetValue"
          align="center"
          width="100"
          label="目标"
        >
        </el-table-column>
        <el-table-column
          prop="doneValue"
          align="center"
          width="100"
          label="已完成"
        >
          <template slot-scope="scope">
            {{ scope.row.doneValue?scope.row.doneValue:0 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="donePer"
          align="center"
          width="120"
          label="完成进度"
        >
          <template slot-scope="scope">
            {{ (scope.row.donePer * 100).toFixed(2) }} %
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          width="200"
          align="center"
          fixed="right"
          label="操作"
        >
          <template slot-scope="scope">
            <!-- v-if="scope.row.directoryLevel == 3" -->
            <el-button
              size="small"
              class="editBtns"
              type="text"
              @click="superviselog(scope.row)"
              v-if="scope.row.dutyUserName || scope.row.jobId"
            >督办记录</el-button>

            <!-- {{scope.row.isSupervise.startsWith('0')}}{{scope.row.status.value}} -->
            <span
              class="btns"
              v-if="$route.query.level == 1 && (scope.row.dutyUserName || scope.row.jobId)"
            >
              <el-button
                class="editBtns"
                type="text"
                size="small"
                v-if="scope.row.status.value == 1 && (scope.row.isSupervise.startsWith('0'))"
                @click="supervisehandel(scope.row)"
              >督办</el-button>
              <!-- {{scope.row.status.value}} -->
              <el-button
                class="editBtns"
                type="text"
                size="small"
                v-if="scope.row.status.value == 1 && scope.row.isSupervise.startsWith('1')"
                disabled
              >已督办</el-button>
            </span>
            <span
              v-if="$route.query.level == 2 && (scope.row.dutyUserName || scope.row.jobId)"
            >
              <el-button
                class="editBtns"
                type="text"
                size="small"
                v-if="scope.row.status.value == 1 && (scope.row.isSupervise.startsWith('00'))"
                @click="supervisehandel(scope.row)"
              >督办</el-button>
              <!-- {{'000'.indexOf('0')}} -->
              <el-button
                class="editBtns"
                type="text"
                size="small"
                v-if="scope.row.status.value == 1 && !scope.row.isSupervise.startsWith('00')"
                disabled
              >已督办</el-button>
            </span>
            <el-button
              style="margin:0.26042vw"
              type="text"
              size="small"
              v-if="scope.row.dutyUserName || scope.row.jobId"
              @click="updataProgress(scope.row.id,scope.row.targetType.value,1)"
            >查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 督办弹窗 -->
      <el-dialog
        title="督办"
        @close="clearForm"
        :visible.sync="supervisePanel"
        width="30%"
      >
        <el-row :gutter="20">
          <el-col
            :span="5"
            style="text-align: right"
          >督办建议</el-col>
          <el-col :span="18">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="supervise.comment"
              maxlength="100"
              rows="6"
              show-word-limit
            >
            </el-input>
          </el-col>
        </el-row>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            @click="supervisePanel = false"
            class="reset"
          >取 消</el-button>
          <el-button
            type="primary"
            :disabled="loadingDB"
            :loading="loadingDB"
            @click="superviseSbmit"
          >确 定</el-button>
        </span>
      </el-dialog>
      <!-- 督办记录弹窗 -->
      <el-dialog
        title="督办记录"
        :visible.sync="superviseLogPanel"
        width="50%"
      >
        <el-table
          :header-cell-style="{
              background: '#F1F4F9',
              color: '#5E6D9B',
              fontWeight: '400',
            }"
          :data="superviseLogData"
          style="width: 100%"
        >
          <el-table-column
            prop="createTime"
            align="center"
            label="日期"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="userName"
            align="center"
            label="督办人"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="comment"
            align="center"
            label="督办建议"
          >
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  data () {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      columns: [
        {
          label: "园校",
          slotName: "targetName",
        },
        {
          label: "指定人",
          slotName: "dutyUser",
        },
        {
          label: "目标",
          slotName: "targetValue",
        },

        {
          label: "已完成",
          slotName: "doneValue",
        },

        {
          label: "完成进度",
          slotName: "donePer",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      uploadList: [],
      supervisePanel: false, //督办操作弹窗
      supervise: {
        //督办表单
        comment: "",
      },
      superviseLogPanel: false, //督办记录弹窗
      superviseLogData: [], //督办记录data
      selectData: [],
      expandRow: [],
      loading: false,
      tableData: [],
      pageFlag: false,
      tableLoading: false,
      targetInfo: {},
      page: {
        num: 1,
        size: 10,
        total: 0,
      },
      supervisePanelId: '',
      superviseLogPanelId: '',
      tableDataInfo: {},
      isOpenAll: false,
      isBatch: false,
      isCheckedAll: false,
      ids: [],
      single: '',
      multiple: 0,
      loadingDB: false,
    };
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (to.query.id) {
        // vm.getTargetInfo(to.query.id);
      } else {
        //vm.goBack();
      }
    });
  },
  components: {
    LTable,
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler (val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler (val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created () {
    this.tableLoading = true;
    this.getTargetInfo(this.$route.query.id)
  },
  methods: {
    //表尾合计
    getSummaries (param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {

        if (index === 4) {
          sums[index] = this.tableDataInfo.targetValue;
          return;
        }
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index === 5) {
          sums[index] = this.tableDataInfo.doneValue;
          return;
        }
        if (index === 6) {
          sums[index] = (Math.round(this.tableDataInfo.donePer * 100 * 100) / 100).toFixed(2) + '%'
          return;
        }
      });

      return sums;
    },
    getPermissionButton,
    isHasPermission,
    getTargetInfo (id) {
      console.log("11111111111111111")
      let requests = [
        this.$api.getTargetInfo({ id }),
        this.$api.getTargetProgressInfo({ id }),
      ];
      Promise.all(requests)
        .then((arr) => {
          console.log("arr===========>",arr);
          this.targetInfo = arr[0].data.data;
          console.log(this.targetInfo)
          this.targetInfo.donePer = (Math.round(this.targetInfo.donePer * 100 * 100) / 100).toFixed(2)
          this.tableDataInfo = arr[1].data.data
          this.tableData = arr[1].data.data.infoList;
          this.isCheckedAll = false
          if (this.tableData.length > 0) {
            this.resetTabDate();
            this.tableData.forEach(e => {
              this.expandRow.push(e.id)
              this.$refs.tableData.toggleRowExpansion(e, true);
            })
          }
          this.expandRow = this.dedupe(this.expandRow)
          console.log(this.expandRow)
          this.loading = false;
          this.tableLoading = false;
        })
        .catch((err) => {
          this.loading = false;
         // this.$message.error("获取失败，准备返回");
          // setTimeout(() => {
          //   this.goBack();
          // }, 1000);
        });
      // requests.push();
      // this.init(id);
      // this.$api.getTargetInfo({ id }).then((res) => {
      //   if (res.data.code == 0 && res.data.data) {
      //     this.targetInfo = res.data.data;
      //   } else {
      //     this.$message.error(res.data.msg);
      //   }
      // });
    },
    supervisehandel (params) {
      // console.log(params)
      if (params.childList) {
        this.selectData = []
        this.isBatch = true
        this.selectData.push(params.id)
        params.childList.forEach(e => {
          if (this.$route.query.level == 1 && e.isSupervise.startsWith('0')) {
            this.selectData.push(e.id)
          } else if (this.$route.query.level == 1 && e.isSupervise.startsWith('00')) {
            this.selectData.push(e.id)
          }
        })
        this.supervisePanel = true
        // console.log(this.selectData)
      } else {
        this.supervisePanel = true
        this.supervisePanelId = params.id
      }
      // console.log(params)
    },
    // 批量督办
    supervisehandelBatchSave (val) {
      if (this.selectData.length == 0) {
        this.$message.error('请选择要督办的目标')
        return
      }
      console.log('批量督办', val)
      this.isBatch = true
      this.supervisePanel = true
      console.log(this.selectData)
      return
      // this.supervisePanelId = params
    },
    updataProgress (id, type, check) {
      let isCheck = null
      if (check) {
        isCheck = check
        if (type > 6) {
          type = 0
        }
      } else {
        isCheck = 0
      }
      this.$router.push({
        path: '/uploadProgress',
        query: {
          lookId: id,
          type: type,
          isCheck: isCheck
        }
      })
    },
    selectable (row) {
      // console.log(row.isSupervise,'ppp')
      if (this.$route.query.level == 1) {
        if (row.isSupervise.startsWith('0') && row.status.value == 1) {
          if (row.childList && row.childList.length > 0) {
            let flag = row.childList.every((info) => { return info.isSupervise.startsWith('1') })
            if (flag) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        if (row.isSupervise.startsWith('00') && row.status.value == 1) {
          return true
        } else {
          return false
        }
      }
    },
    superviselog (row) {
      // console.log(row)
      this.$api.supervisionRecordList(row.id).then((res) => {
        this.superviseLogPanel = true
        if (res.data.code == 0) {
          this.superviseLogData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //查看
    handleDetail (id, row) {
      this.$router.push({
        path: '/registrationPark?detail=1',
        query: {
          id: id,
          type: this.$route.query.type,
          detailId: this.$route.query.lookId
        }
      })
    },
    clearForm () {
      this.isBatch = false
      this.supervise.comment = ''
    },
    // 修改督办
    superviseSbmit () {
      this.loadingDB = true
      // this.supervise.comment
      // console.log(this.selectData)
      if (this.isBatch) {
        // 批量督办
        this.$api.saveSupervisionRecordBatchSave({
          comment: this.supervise.comment,
          detailIds: this.selectData.join(','),
          flag: this.$route.query.flag,
        }).then((res) => {
          this.loadingDB = false
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.supervisePanel = false
            this.getTargetInfo(this.$route.query.id)
          } else if (res.data.code == 3) {
            this.getTargetInfo(this.$route.query.id)
            this.$message.error(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          this.loadingDB = false
        });
      } else {
        // 单个督办
        this.$api.saveSupervisionRecord({
          comment: this.supervise.comment,
          detailId: this.supervisePanelId,
          flag: this.$route.query.flag,
        }).then((res) => {
          this.loadingDB = false
          if (res.data.code == 0) {
            // this.superviseLogData = res.data.data;
            // this.$message.success(res.data.msg);
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.supervisePanel = false
            this.getTargetInfo(this.$route.query.id)
          } else if (res.data.code == 3) {
            this.getTargetInfo(this.$route.query.id)
            this.$message.error(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(error => {
          this.loadingDB = false
        });
      }
    },
    handleCurrentChange () { },
    handleSizeChange () { },
    goBack () {
      this.$router.go(-1);
    },
    handleSelect (arr) {
      this.ids = arr.map((obj) => obj.id)
      // console.log(arr,'handleSelect')
      let selectData = []
      if (arr) {
        arr.forEach(e => {
          selectData.push(e.id)
        })
      }
      this.selectData = selectData;
    },
    handleSelectRow (arr, row) {
      // console.log(row,'row')
      console.log(this.ids.indexOf(row.id), 'pppppppppppp')
      if (this.ids.indexOf(row.id) == -1) {
        if (row.childList) {
          row.childList.forEach(e => {
            if (this.$route.query.level == 1 && e.isSupervise.startsWith('0')) {
              this.$refs.tableData.toggleRowSelection(e, true);
            } else if (this.$route.query.level == 1 && e.isSupervise.startsWith('00')) {
              this.$refs.tableData.toggleRowSelection(e, true);
            }
          })
        }
      } else {
        if (row.childList) {
          row.childList.forEach(e => {
            if (this.$route.query.level == 1 && e.isSupervise.startsWith('0')) {
              this.$refs.tableData.toggleRowSelection(e, false);
            } else if (this.$route.query.level == 1 && e.isSupervise.startsWith('00')) {
              this.$refs.tableData.toggleRowSelection(e, false);
            }
          })
        }
      }
    },
    changeOpenAll (val) {
      console.log(val)
      this.expandRow = []
      this.resetTabDate();
      if (val) {
        if (this.tableData) {
          this.tableData.forEach(e => {
            this.expandRow.push(e.id)
            this.$refs.tableData.toggleRowExpansion(e, true);
            if (e.childList) {
              e.childList.forEach(ele => {
                this.expandRow.push(ele.id)
                this.$refs.tableData.toggleRowExpansion(ele, true);
              })
            }
          })
        }
        // this.$refs.tableData.toggleAttribute
      } else {
        if (this.tableData) {
          this.tableData.forEach(e => {
            this.expandRow.push(e.id)
          })
        }
      }
      this.expandRow = this.dedupe(this.expandRow)
    },
    selectAll (val) {
      console.log(val)
      if (this.ids.indexOf(this.tableData[0].id) != -1) {
        if (this.tableData) {
          this.tableData.forEach(e => {
            // this.expandRow.push(e.id)
            if (e.childList) {
              e.childList.forEach(ele => {
                if (this.$route.query.level == 1 && ele.isSupervise == '000') {
                  this.$refs.tableData.toggleRowSelection(ele, true);
                }
                if (this.$route.query.level == 2 && ele.isSupervise != '010') {
                  this.$refs.tableData.toggleRowSelection(ele, true);
                }
              })
            }
          })
        }
      } else {
        this.$refs.tableData.clearSelection();
        // setTimeout(()=>{
        // },100)
      }
    },
    //收起展开菜单
    resetTabDate (arr) {
      this.expandRow = [];
      let data = arr || this.tableData;
      data.forEach((item) => {
        this.$refs.tableData.toggleRowExpansion(item, false);
        if (
          Object.prototype.toString.call(item.itemList) ==
          "[object Array]"
        ) {
          this.resetTabDate(item.itemList);
        }
      });
    },
    dedupe (array) {
      return Array.from(new Set(array));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
  }
}
.batchBtn {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.editBtns {
  margin-left: 6px;
}
/deep/.el-table_1_column_7.is-leaf {
  color: #c6000b;
}
/deep/.el-table_1_column_5.is-leaf {
  color: #c6000b;
}
/deep/.el-table_1_column_6.is-leaf {
  color: #c6000b;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #c0c4cc !important;
}
</style>
